<template>
  <div>
    <div class="bg-white rounded">
      <!--begin::Stepper-->
      <div
        class="stepper stepper-links d-flex flex-column"
        id="kt_create_account_stepper"
        ref="horizontalWizardRef"
      >
        <!--begin::Nav-->
        <div class="py-5 m-5">
          <el-steps :active="active" finish-status="success">
            <!-- STEP EDITOR -->
            <el-step title="Edit Gambar"> </el-step>
            <!-- END STEP EDITOR -->
            <el-step title="Optimize Text"></el-step>
            <el-step title="Form"></el-step>
          </el-steps>

          <!-- STEP 1 -->
          <div
            v-if="active == 0"
            class="imageEditorApp"
            style="margin-bottom: 60px; margin-top: 30px"
          >
            <div class="row mb-1">
              <div class="col-md-8">
                <div class="pull-right">
                  <input
                    class="btnFileLoad"
                    type="file"
                    ref="oFileHandler"
                    style="display: none"
                    v-on:change="onFileChange"
                  />
                  <button
                    class="btn btn-success"
                    @click="fileLoad()"
                    style="margin-right: 10px"
                  >
                    Load Gambar
                  </button>

                  <button
                    v-if="switchEnable"
                    class="btn btn-info me-3"
                    ref="simpanImage"
                    @click="simpanImage()"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-if="isloadingGambar"
                    ></span>
                    Simpan Gambar
                  </button>
                  <button
                    class="btn btn-primary"
                    ref="prosesImage"
                    @click="ProsesImage()"
                    style="margin-right: 10px"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-if="isloading"
                    ></span>
                    Proses Gambar
                  </button>
                  <button
                    class="btn btn-primary"
                    ref="magictool"
                    style="margin-right: 10px"
                    @click="magicTool()"
                  >
                    Magic Tool
                  </button>
                  <button
                    v-if="modalShow"
                    ref="editNumber"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_konfigurasi"
                  >
                    Edit Penomoran
                  </button>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  class="form-check form-switch form-check-custom form-check-solid"
                  style="margin-top: 10px; float: right"
                >
                  <label class="form-check-label mx-3" for="flexSwitchDefault"
                    >Multipe Image</label
                  >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="switchEnable"
                    @click="switchMultiple"
                    id="flexSwitchDefault"
                  />
                </div>
              </div>
            </div>
            <div class="row my-3" v-if="switchEnable">
              <div class="col-md-12">
                <button
                  v-for="(item, index) in arrayImage"
                  :key="index"
                  :class="
                    item.isActive
                      ? 'btn btn-info me-3'
                      : 'btn btn-secondary me-3'
                  "
                  ref="prosesImage"
                  @click="buttonTab(item.name)"
                >
                  {{ item.name }}
                </button>
              </div>
            </div>
            <tui-image-editor
              :key="keyEditor"
              class="mb-3"
              ref="editor"
              :include-ui="useDefaultUI"
              :options="options"
              @addObjectAfter="addObjectAfter"
              @objectActivated="objectActivated"
              @objectDeactivated="objectDeactivated"
              @objectScaled="objectScaled"
              @objectMoved="objectMoved"
            ></tui-image-editor>
          </div>
          <!-- END STEP 1 -->

          <!-- STEP 2 -->
          <div class="row" v-if="active == 1">
            <div class="col-lg-6">
              <div class="row my-3">
                <div class="col-md-12" v-if="switchEnable">
                  <button
                    v-for="(item, index) in arrayImage"
                    :key="index"
                    class="btn btn-light me-3"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_edit_image"
                    @click="url = item.dataImage"
                  >
                    {{ item.name }}
                  </button>
                </div>
                <div class="col-md-12" v-else>
                  <button
                    class="btn btn-light me-3"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_edit_image"
                  >
                    Lihat Gambar
                  </button>
                </div>
              </div>
              <div class="row">
                <div>
                  <label class="fw-bold fs-6">Judul Berita</label>
                  <div class="row">
                    <div class="col-12 fv-row">
                      <el-input
                        type="text"
                        name="title"
                        v-model="title"
                      ></el-input>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <!-- <ErrorMessage name="title" /> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <label class="fw-bold fs-6">Konten Berita</label>
                  <div class="row">
                    <div class="col-12 fv-row">
                      <el-input
                        v-model="content"
                        type="textarea"
                        rows="15"
                      ></el-input>

                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <!-- <ErrorMessage name="content" /> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="row">
                <div
                  class="col-md-12 p-3"
                  style="padding-left: 50px !important"
                  :style="file_image != '' ? 'display:block;' : 'display:none'"
                >
                  <label for="projectname" class="form-label fs-6 mb-3"
                    >Judul Berita</label
                  >
                  <div class="align-items-center mb-5">
                    <Draggable v-model="dataTitle" :key="keyDragableTitle">
                      <template v-slot:item="{ item }">
                        <div v-if="item.value != ''">
                          <div class="card card-border">
                            <div class="card-body bg-light mb-3">
                              <div class="row">
                                <div class="col-md-8">
                                  {{ item.value }}
                                </div>
                                <div class="col-md-4">
                                  <a
                                    href="#"
                                    class="btn btn-primary btn-hover-scale me-5"
                                    >{{ item.posisi }}</a
                                  >
                                  <a
                                    @click="removeTitle(item.id, item.value)"
                                    class="btn btn-danger btn-hover-scale me-5"
                                    >X</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </Draggable>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-md-12 p-3"
                  style="padding-left: 50px !important"
                  :style="file_image != '' ? 'display:block;' : 'display:none'"
                >
                  <label for="projectname" class="form-label fs-6 mb-3"
                    >Konten Berita</label
                  >
                  <div class="align-items-center mb-5">
                    <Draggable v-model="dataContent" :key="keyDragableContent">
                      <template v-slot:item="{ item }">
                        <div v-if="item.value != ''">
                          <div class="card card-border">
                            <div class="card-body bg-light mb-3">
                              <div class="row">
                                <div class="col-md-8">
                                  {{ item.value }}
                                </div>
                                <div class="col-md-4">
                                  <a
                                    href="#"
                                    class="btn btn-primary btn-hover-scale me-5"
                                    >{{ item.posisi }}</a
                                  >
                                  <a
                                    @click="removeContent(item.id, item.value)"
                                    class="btn btn-danger btn-hover-scale me-5"
                                    >X</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </Draggable>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- STEP 2 -->

          <!-- STEP 2 -->
          <div v-if="active == 2">
            <Form
              class="form"
              novalidate="novalidate"
              @submit="sendData"
              :validation-schema="MediaCetakFormValidator"
            >
              <!-- JUDUL BERITA -->
              <!--begin::Input group-->
              <div class="row mb-6">
                <div class="row my-3">
                  <div class="col-md-12" v-if="switchEnable">
                    <a
                      href="#"
                      v-for="(item, index) in arrayImage"
                      :key="index"
                      class="btn btn-light me-3"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_edit_image"
                      @click="url = item.dataImage"
                    >
                      {{ item.name }}
                    </a>
                  </div>
                  <div class="col-md-12" v-else>
                    <button
                      class="btn btn-light me-3"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_edit_image"
                    >
                      Lihat Gambar
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mb-6">
                <!--begin::Col-->
                <label class="fw-bold fs-6 required">Judul Berita</label>
                <div class="row">
                  <div class="col-12 fv-row">
                    <Field
                      type="text"
                      class="form-control form-control-lg"
                      name="title"
                      v-model="title"
                    ></Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="title" />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!-- Media BERITA -->
              <!--begin::Input group-->

              <div class="row mb-6">
                <!--begin::Col-->
                <label class="fw-bold fs-6 required">Nama Media</label>
                <div class="row">
                  <div class="col-12 fv-row">
                    <Field name="account" v-slot="{ field }">
                      <el-select
                        v-bind="field"
                        filterable
                        placeholder="Nama Media"
                        allow-create
                        v-model="account"
                        reserve-keyword
                        :loading="loading"
                        :remote-method="remoteMethod"
                        autocomplete="off"
                        :remote="true"
                        class="d-block"
                      >
                        <el-option
                          v-for="item in media_options"
                          :key="item.value"
                          :label="item.nama"
                          :value="item.nama"
                          @click="changeAccount(item)"
                        />
                      </el-select>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="account" />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!-- Tanggal BERITA -->
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Col-->
                <label class="fw-bold fs-6 required">Tanggal Berita</label>
                <div class="row">
                  <div class="col-12 fv-row">
                    <Field name="date" v-slot="{ handleBlur, handleChange }">
                      <el-form>
                        <!--begin::Datepicker-->
                        <el-form-item prop="dueDate" class="mb-0">
                          <el-date-picker
                            @blur="handleBlur"
                            @change="handleChange"
                            style="width: 100% !important"
                            v-model="date"
                            placeholder="Tanggal Berita"
                            format="YYYY-MM-DD HH:mm:ss"
                            type="datetime"
                          />
                        </el-form-item>
                        <!--end::Datepicker-->
                      </el-form>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="date" />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!-- Page -->
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Col-->
                <label class="fw-bold fs-6 required">Page</label>
                <div class="row">
                  <div class="col-12 fv-row">
                    <Field
                      type="text"
                      class="form-control form-control-lg"
                      name="page"
                      v-model="page"
                    ></Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="page" />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!-- Rate -->
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Col-->
                <label class="fw-bold fs-6 required">Rate Hitam Putih</label>
                <div class="row">
                  <div class="col-12 fv-row">
                    <div class="row">
                      <div class="col-3">
                        <Field
                          type="text"
                          name="columnBW"
                          placeholder="Kolom Hitam Putih"
                          @keyup="countAD('bw')"
                          @keypress="isNumber"
                          @paste="onPaste"
                          v-model="columnBw"
                          class="form-control form-control-lg"
                          :disabled="isColumnBw ? false : true"
                        />
                        <div class="fv-plugins-message-container">
                          <div class="fv-help-block">
                            <ErrorMessage name="columnBW" />
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <Field
                          type="text"
                          name="rate_bw"
                          id="rateBw"
                          placeholder="Rate Hitam Putih"
                          autocomplete="off"
                          @keypress="isNumber"
                          @paste="onPaste"
                          :key="keyRateBw"
                          class="form-control form-control-lg"
                          v-model="rate_bw"
                        />
                        <div class="fv-plugins-message-container">
                          <div class="fv-help-block">
                            <ErrorMessage name="rate_bw" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!-- Rate -->
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Col-->
                <label class="fw-bold fs-6 required">Rate Berwarna</label>
                <div class="row">
                  <div class="col-12 fv-row">
                    <div class="row">
                      <div class="col-3">
                        <Field
                          type="text"
                          name="columnFc"
                          placeholder="Kolom Berwarna"
                          @keyup="countAD('fc')"
                          @keypress="isNumber"
                          @paste="onPaste"
                          v-model="columnFc"
                          class="form-control form-control-lg"
                          :disabled="isColumnFc ? false : true"
                        />
                        <div class="fv-plugins-message-container">
                          <div class="fv-help-block">
                            <ErrorMessage name="columnBW" />
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <Field
                          type="text"
                          name="rate_fc"
                          id="rateFc"
                          placeholder="Rate Berwarna"
                          autocomplete="off"
                          @keypress="isNumber"
                          @paste="onPaste"
                          :key="keyRateFc"
                          class="form-control form-control-lg"
                          v-model="rate_fc"
                        />
                        <div class="fv-plugins-message-container">
                          <div class="fv-help-block">
                            <ErrorMessage name="rate_bw" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!-- Jurnalis BERITA -->
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Col-->
                <label class="fw-bold fs-6 required">Jurnalis</label>
                <div class="row">
                  <div class="col-12 fv-row">
                    <Field
                      type="text"
                      name="journalist"
                      class="form-control form-control-lg"
                      v-model="journalist"
                    ></Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="journalist" />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!-- Konten BERITA -->
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Col-->
                <label class="fw-bold fs-6 required">Konten Berita</label>
                <div class="row">
                  <div class="col-12 fv-row">
                    <Field name="content" v-slot="{ field }">
                      <el-input
                        type="textarea"
                        rows="10"
                        v-model="content"
                        v-bind="field"
                      />
                    </Field>
                    <a
                      size="small"
                      class="btn btn-sm btn-primary mt-5"
                      type="primary"
                      @click="fixOcr"
                    >
                      <i class="fas fa-align-justify text-white"></i> Fix OCR
                      Text
                    </a>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="content" />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <button
                type="submit"
                v-if="active == 2"
                class="btn btn-primary"
                style="margin-top: 12px; margin-left: 10px; float: right"
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  v-if="isSubmit"
                ></span>
                Finish
              </button>
            </Form>
          </div>
          <!-- END STEP 2 -->
          <el-button
            v-if="active < 2"
            :disabled="!isProses"
            style="margin-top: 12px; margin-left: 10px; float: right"
            @click="next"
            >Next step</el-button
          >
          <el-button
            v-if="active > 0"
            style="margin-top: 12px; margin-left: 10px; float: right"
            @click="back"
            >Back step</el-button
          >
        </div>
        <!--end::Nav-->
      </div>
      <!--end::Stepper-->
    </div>
  </div>
  <div
    class="modal fade"
    id="kt_modal_edit_image"
    tabindex="-1"
    ref="modalEditUser"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-800px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header">
          <!--begin::Modal title-->
          <h2>Lihat Gambar</h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
          <div class="row">
            <div class="col-md-12">
              <img
                :src="
                  switchEnable
                    ? url
                    : dataResult.result != null
                    ? 'data:image/png;base64,' + dataResult.result.image
                    : ''
                "
                alt=""
                width="700"
                height="750"
              />
            </div>
          </div>
          <!--begin::Form-->
          <!--end::Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>

  <div
    class="modal fade"
    id="kt_modal_konfigurasi"
    tabindex="-1"
    ref="modalKonfigurasi"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-800px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header">
          <!--begin::Modal title-->
          <h2>Edit Penomoran</h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            @click="closeModal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y">
          <!--begin::Form-->
          <label class="fw-bold fs-6">Posisi Text</label>
          <div class="row">
            <div class="col-3">
              <Field
                type="text"
                name="posisiAwal"
                placeholder="Posisi Awal"
                @keypress="isNumber"
                @paste="onPaste"
                v-model="posisiAwal"
                class="form-control form-control-lg"
                :disabled="true"
              />
            </div>
            <div class="col-3">
              <Field
                type="text"
                name="posisiAkhir"
                placeholder="Posisi Akhir"
                @keypress="isNumber"
                @paste="onPaste"
                v-model="posisiAkhir"
                class="form-control form-control-lg"
              />
            </div>
          </div>
          <div class="text-center pt-15">
            <button
              id="kt_modal_new_card_cancel"
              class="btn btn-danger me-3"
              data-bs-dismiss="modal"
              @click="deleteNomor(indexPenomoran)"
            >
              Hapus Nomor
            </button>

            <button
              @click="changeNomor"
              ref="editSave"
              type="submit"
              id="kt_modal_edit_submit"
              data-bs-dismiss="modal"
              class="btn btn-primary"
            >
              <span class="indicator-label"> Simpan Perubahan </span>
            </button>
          </div>
          <!--end::Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
</template>
<script lang="js">
import "tui-color-picker/dist/tui-color-picker.css";
import "tui-image-editor/dist/tui-image-editor.css";
import 'form-wizard-vue3/dist/form-wizard-vue3.css';
import ImageEditor from "@toast-ui/vue-image-editor/src/ImageEditor.vue";
import { ocrGambar, ModelOcr,uploadGambar,magictool } from "./streams";
import Draggable from "vue3-draggable";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from "yup";
import moment from "moment";
import { useRouter } from "vue-router";
export default {
  components: {
    'tui-image-editor': ImageEditor,
    Draggable,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
        keyEditor : 0,
        url: '',
        modalShow: false,
        switchEnable: false,
        arrayImage: [],
        temporaryImage: [],
        currentTabIndex:0,
        useDefaultUI: true,
        options: {
          includeUI: {
            menuBarPosition: "top",
          },
          selectionStyle: {
            cornerSize: 20,
            rotatingPointOffset: 70
          },
        },
        dataTitle: [],
        dataContent : [],
        dataResult : [],
        media_options : [],
        dataAccountMedia : [],
        valueTitle : [],
        valueContent : [],
        title:"",
        file:null,
        fileCreateObjectURL:null,
        content : "",
        file_image : "",
        account : "",
        rate_fc : "",
        rate_bw : "",
        columnFc : "",
        columnBw : "",
        journalist : "",
        date : "",
        page : "",
        valueBerita : "",
        isProses : false,
        loading : false,
        isColumnBw : false,
        isColumnFc : false,
        isSubmit : false,
        active: 0,
        keyRateBw: 0,
        keyRateFc: 0,
        keyDragableContent: 0,
        keyDragableTitle: 0,
        MediaCetakFormValidator : Yup.object().shape({
        title: Yup.string().required().label("Judul Berita Tidak Boleh Kosong!"),
        account:Yup.string().when("title", {
          is: () => this.account === "",
          then: Yup.string().required("Jurnalis Tidak Boleh Kosong!"),
          otherwise: Yup.string().notRequired(),
        }),
        columnFc: Yup.string().when("account", {
          is: (val) => this.isColumnFc.value,
          then: Yup.string().required("Kolom Berwarna Tidak Boleh Kosong!"),
          otherwise: Yup.string().notRequired(),
        }),
        columnBW: Yup.string().when("account", {
          is: (val) => this.isColumnBw.value,
          then: Yup.string().required("Kolom Hitam Putih Tidak Boleh Kosong!"),
          otherwise: Yup.string().notRequired(),
        }),
        content: Yup.string().when("title", {
          is: () => this.content === "",
          then: Yup.string().required("Konten Berita Tidak Boleh Kosong!"),
          otherwise: Yup.string().notRequired(),
        }),
        date:Yup.string().when("title", {
          is: () => this.date === "",
          then: Yup.string().required("Tanggal Berita Tidak Boleh Kosong!"),
          otherwise: Yup.string().notRequired(),
        }),
        journalist: Yup.string().required().label("Jurnalis Berita Tidak Boleh Kosong!"),
        page: Yup.string().required().label("Page Berita Tidak Boleh Kosong!"),
        rate_bw: Yup.string().required().label("Rate Hitam Putih Tidak Boleh Kosong!"),
        rate_fc: Yup.string().required().label("Rate Berwarna Tidak Boleh Kosong!"),
      }),
      isloading : false,
      isloadingGambar : false,
      file_name : "",
      activeRectangle : "",
      deleteRectangle : [],
      imageEditor : null,
      posisiAwal : "",
      posisiAkhir : "",
      indexPenomoran : ""
    };
  },
  methods: {
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    back() {
        if(this.active-- > 2) this.active = 0
        if(this.active == 0){
          setTimeout(() => {
            let url = ""
            if(this.switchEnable){
              url = this.arrayImage[0].url
            }else{
              url = this.temporaryImage[0].url
            }
            this.$refs.editor.invoke(
              "loadImageFromURL",
              url,
              "My sample image"
            ).then(result=>{
                this.$refs.editor.invoke('ui.activeMenuEvent')
                if(this.switchEnable){
                  if(this.arrayImage[0].rectangle.length > 0){
                    this.arrayImage[0].rectangle.forEach(element => {
                        this.createRectangle(element)
                    });
                  }
                }else{
                  if(this.temporaryImage[0].rectangle.length > 0){
                    this.temporaryImage[0].rectangle.forEach(element => {
                        this.createRectangle(element)
                    });
                  }
                }
                // this.$refs.editor.invoke('ui.resizeEditor', { imageSize: { newWidth: result.newWidth, newHeight: result.newHeight }})
            })
          }, 2000);
        }
    },
    async sendData(values){
      let thiss = this.$router;
      this.isSubmit = true;
      var formData = new FormData();
      formData.append("file", this.file);
      var response = await uploadGambar(formData);
      if(response['message'] == "Berhasil"){
        let file_source = response['result']['file']
        let date = moment(this.date).format("YYYY-MM-DD HH:mm:ss");
        let data = {
          "date" : date,
          "title" : values.title,
          "content" : this.content,
          "account" : this.account,
          "journalist" : this.journalist,
          "rate_bw" : this.rate_bw,
          "rate_fc" : this.rate_fc,
          "file_source" : file_source,
          "page" : this.page,
          "source" : "media_cetak",
          "emotion" : "",
          "sentiment" : ""
        }
        ApiService.post("/streams/save", { data: data })
        .then((response) => {
          if (response.status == 200) {
            Swal.fire({
              text: "Berhasil!",
              icon: "success",
              confirmButtonText: "Ok",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-light-primary",
              },
            }).then(() => {
                // this.isProses = true;
                // this.next();
                this.isSubmit = false;
                thiss.push({name:'berita'})
            })
          } else {
            this.isSubmit = false;
            alert("Gagal menyimpan data");
          }
        })
        .catch((error) => {
          this.isSubmit = false;
          console.log(error);
        })
      }
    },
    async prosesUpload(file) {
      var formData = new FormData();
      formData.append("file", file);
      var response = await uploadGambar(formData);
      return response['file']
      
    },
    sliceTitleContent (data)  {
      if(this.switchEnable == false){
        this.dataTitle= [];
        this.dataContent= [];
      }
      for (let index = 0; index < data["result"]["ocr"].length; index++) {
        var element = data["result"]["ocr"][index];
        if (element["type"] == "title") {
          this.dataTitle.push(element);
        } else {
          this.dataContent.push(element);
        }
      }
      
    },
  async ProsesImage() {
      this.isloading = true;
      if(this.switchEnable){
        for (let index = 0; index < this.arrayImage.length; index++) {
            var file = this.dataURLtoFile(this.arrayImage[index].dataImage,"file.jpeg");
            var formData = new FormData();
            formData.append("file",file);
            formData.append("data",JSON.stringify(this.arrayImage[index].rectangle));
            var response =  await ocrGambar(formData);
            this.dataResult = response;
            if(this.dataResult['message'] == "Berhasil"){
              if(this.dataResult['result']["ocr"].length > 0){
                this.sliceTitleContent(this.dataResult);
                this.mergeContent()
                this.mergeTitle()
              }
            }
        }
        if(this.dataContent.length != 0 || this.dataTitle.length != 0){
          Swal.fire({
            text: "Berhasil!",
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          }).then(() => {
            this.isloading = false;
              this.isProses = true;
              this.next();
          });
        }else{
          this.isProses = false;
          this.isloading = false;
          Swal.fire({
            text: "Judul berita dan isi berita tidak terdekteksi!",
            icon: "warning",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          })
        }
      }else{
        this.$refs.prosesImage.setAttribute('disabled', true);
        let data = this.$refs.editor.invoke("toDataURL");
        this.file_image = data;
        let file = this.dataURLtoFile(data,"file.jpeg");
        // setTimeout(() => {
        this.getSizeImage(this.file_image).then(async (img) =>  {
            this.temporaryImage[0].width = img.naturalWidth
            this.temporaryImage[0].height = img.naturalHeight
            let formData = new FormData();
            let width = 0;
            let height = 0;
            formData.append("file",this.file);
            formData.append("data",JSON.stringify(this.temporaryImage[0].rectangle));
            console.log(this.temporaryImage)
            let response = await ocrGambar(formData);
            this.dataResult = response;
            if(this.dataResult.message == "Berhasil"){
              
              if(this.dataResult.result.ocr.length > 0){
                this.sliceTitleContent(this.dataResult);
                this.mergeContent()
                this.mergeTitle()
                Swal.fire({
                  text: "Berhasil!",
                  icon: "success",
                  confirmButtonText: "Ok",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-light-primary",
                  },
                }).then(() => {
                  this.isloading = false;
                    this.isProses = true;
                    this.next();
                });
              }else{
                this.isloading = false;
                this.isProses = true;
                this.$refs.prosesImage.removeAttribute('disabled');
                Swal.fire({
                  text: "Terjadi Kesalahan",
                  icon: "error",
                  confirmButtonText: "Ok",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-light-primary",
                  },
                })
              }
            }
        });
          
        // },1500)
       
      }
    },
    fileLoad() {
      this.hiddenFileElement = this.$refs.oFileHandler;
      this.hiddenFileElement.click();
    },
    onFileChange(event) {
      this.temporaryImage = []
      let URL = window.URL;
      this.fileCreateObjectURL = URL.createObjectURL(event.target.files[0])
      this.file = event.target.files[0]
      this.file_name = event.target.files[0].name
      this.url = this.fileCreateObjectURL
      this.isProses = false
      this.getSizeImage(this.fileCreateObjectURL).then(img => {
        if(img.naturalHeight > 1000){
          this.options.includeUI.menuBarPosition = "left"
        }else{
          this.options.includeUI.menuBarPosition = "top"
        }
        this.keyEditor += 1
      })

      setTimeout(() => {
        this.$refs.editor.invoke(
            "loadImageFromURL",
            this.fileCreateObjectURL,
            event.target.files[0].name
          ).then(result=>{
              this.$refs.editor.invoke('ui.activeMenuEvent')
              if(this.switchEnable == false){
                this.temporaryImage = []
                this.arrayImage = []
              }
              this.temporaryImage.push({
                name : this.$refs.editor.invoke('getImageName'),
                isActive : false,
                url : this.fileCreateObjectURL,
                dataImage : null,
                type : "image",
                width : result.oldWidth,
                height : result.oldHeight,
                rectangle : []
              })
          })
      }, 2000);
    },
    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    },
    remoteMethod(query) {
      if (query.length > 2) {
        this.loading = true;
        let url = "/media?media_like=" + query + "&limit=100&page=1&source=media_cetak";
        ApiService.get(url)
          .then((response) => {
            this.media_options = response.data.data;
            return this.media_options.value;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
        return [];
      } else {
        this.media_options = [];
      }
    
    },
    changeAccount(data){
      if ("bw" in data) {
        if (data.bw.rate > 0) {
          this.isColumnBw = true;
        } else {
          this.isColumnBw = false;
        }
      } else {
        this.isColumnBw = false;
        this.rate_bw = "0";
        this.columnBw = "0";
      }

      if ("fc" in data) {
        if (data.fc.rate > 0) {
          this.isColumnFc = true;
        } else {
          this.isColumnFc = false;
        }
      } else {
        this.rate_fc = "0";
        this.isColumnFc = false;
        this.columnFc = "0";
      }
      this.dataAccountMedia = data;
    },
    isNumber (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        (charCode !== 46 || charCode !== 190)
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    onPaste (evt) {
      let value = evt.clipboardData.getData("text");
      if (value.match(/^[0-9]+$/) != null) {
        if (evt.currentTarget.id == "rateBw") {
          this.rate_bw = value;
          this.keyRateBw += 1;
        } else {
          this.rate_fc = value;
          this.keyRateFc += 1;
        }
      } else {
        if (evt.currentTarget.id == "rateBw") {
          this.rate_bw = "";
          this.keyRateBw += 1;
        } else {
          this.rate_fc = "";
          this.keyRateFc += 1;
        }
      }
    },
    countAD (type)  {
      let lebar = 0;
      let rate = 0;
      let valueAD = 0;
      if (type == "bw") {
        lebar = this.dataAccountMedia["bw"]["lebar"];
        rate = this.dataAccountMedia["bw"]["rate"];
        valueAD = Number(this.columnBw) * lebar * rate;
        this.rate_bw = valueAD.toString();
      } else {
        lebar = this.dataAccountMedia["fc"]["lebar"];
        rate = this.dataAccountMedia["fc"]["rate"];
        valueAD = Number(this.columnFc) * lebar * rate;
        this.rate_fc = valueAD.toString();
      }
    },
    removeContent (id, text) {
      const index = this.dataContent.findIndex((el) => el.id === id);
      if (index > -1) {
        this.dataContent.splice(index, 1);
      }

      const check = this.valueContent.findIndex((el) => el == text);
      if (check > -1) {
        this.valueContent.splice(check, 1);
      }

      this.content = this.valueContent.toString().replaceAll(",", " ");
      this.keyDragableContent += 1;
      // this.checkValue();
      return this.dataContent;
    },
    removeTitle  (id, text) {
      const index = this.dataTitle.findIndex((el) => el.id === id);
      if (index > -1) {
        this.dataTitle.splice(index, 1);
      }
      const check = this.valueTitle.findIndex((el) => el == text);
      if (check > -1) {
        this.valueTitle.splice(check, 1);
      }

      this.title = this.valueTitle.toString().replaceAll(",", " ");
      this.keyDragableTitle += 1;
      // this.checkValue();
      return this.dataTitle;
    },
    mergeContent(){
      this.valueContent = [];
      for (let index = 0; index < this.dataContent.length; index++) {
        this.valueContent.push(this.dataContent[index]["value"]);
        
      }
      this.content = "";
      this.content = this.valueContent.toString().replaceAll(",", " ");
      return this.dataContent;
    },
    mergeTitle(){
      this.valueTitle = [];
      for (let index = 0; index < this.dataTitle.length; index++) {
        this.valueTitle.push(this.dataTitle[index]["value"]);
        
      }
      this.title = "";
      this.title = this.valueTitle.toString().replaceAll(",", " ");
      return this.dataTitle;
    },
    fixOcr(){
      if (this.content != null) {
        let text = this.content.split("\n\n");
        let newText = "";

        for (let i = 0; i < text.length; i++) {
          let perTextArray = text[i].split("\n");
          for (let j = 0; j < perTextArray.length; j++) {
            if (perTextArray[j].charAt(perTextArray[j].length - 1) == "-") {
              newText += perTextArray[j].replace(/-/g, "");
            } else {
              newText += perTextArray[j] + " ";
            }
          }
          newText += "\n";
        }
        this.content = newText;
        this.valueBerita = newText;
      }
    },
    objectActivated(data){
      this.deleteRectangle.push(data);
      this.activeRectangle = this.deleteRectangle[this.deleteRectangle.length - 1];
      let index = this.temporaryImage.findIndex(e => e.name === this.file.name);

      if (index !== -1) {
        let rectangle = this.temporaryImage[index].rectangle.find(el => {
          return el.height === this.activeRectangle.height && el.width === this.activeRectangle.width && el.top === this.activeRectangle.top && el.left === this.activeRectangle.left;
        });
        
        if (rectangle && (data.type === 'rect' || data.type === 'i-text')) {

          if (rectangle && 'posisi' in rectangle) {
            this.key += 1;
            this.modalShow = true;
            this.posisiAwal = rectangle.posisi || '';
            
            setTimeout(() => {
              this.$refs.editNumber.click();
              this.$refs.editor.invoke('deactivateAll');
            }, 1000);
          } else {
            this.modalShow = false;
          }
        } else {
          this.modalShow = false;
        }
      } else {
        this.modalShow = false;
      }

      this.deleteRectangle = [];
      this.activeRectangle = null;
    },
    objectDeactivated() {
      this.activeRectangle = null;
      this.deleteRectangle = [];
      this.$refs.editor.invoke('setActiveObject',null);
       // Reset active rectangle when object is deactivated
    },
    addObjectAfter(data) {
      var index = this.temporaryImage.findIndex((e) => e.name == this.file_name)
      var indexParent = this.arrayImage.findIndex((e) => e.name == this.file_name)
      if (index != -1) {
        let temporaryImage = this.temporaryImage[index]
        if (data.type == "rect") {
          console.log(data.id)
          this.$refs.editor.invoke('setObjectProperties', data.id, {
            opacity: 0.5,
            fill: 'blue',
          })
        }

        let rectangle = temporaryImage.rectangle
        let text = temporaryImage.text

        let posisi = rectangle.length + 1
        let textOptions = {
          content: posisi,
          styles: {
            fill: 'white',
            fontSize: 100,
            fontWeight: 'bold'
          },
          position: {
            x: data.left,
            y: data.top
          }
        }

        if (rectangle.length > 0) {
          setTimeout(() => {
            this.$refs.editor.invoke('addText', posisi.toString(), textOptions).then(objectProps => {
              textOptions.id = objectProps.id
              textOptions.content = posisi.toString()
              text.push(textOptions)
              data.posisi = posisi
            });
          }, 1000);
        } else {
          setTimeout(() => {
            this.$refs.editor.invoke('addText', '1', textOptions).then(objectProps => {
              textOptions.id = objectProps.id
              textOptions.content = '1'
              text.push(textOptions)
              data.posisi = 1
            });
          }, 1000);
        }
        rectangle.push(data)
      } 
      this.$refs.editor.invoke('deactivateAll')
    },
    objectScaled(data) {
      var index = this.temporaryImage.findIndex((e) => e.name == this.file_name)
      var indexParent = this.arrayImage.findIndex((e) => e.name == this.file_name)
      if(indexParent != -1){
        var indexRectangleParent = this.arrayImage[indexParent].rectangle.findIndex((el) => el.id == data.id)
        this.arrayImage[indexParent].rectangle[indexRectangleParent] = data
      }else if(index != -1){
        var indexRectangle = this.temporaryImage[index].rectangle.findIndex((el) => el.id == data.id)
        this.temporaryImage[index].rectangle[indexRectangle] = data
      }
    },
    objectMoved(data){
      var index = this.temporaryImage.findIndex((e) => e.name == this.file_name)
      var indexParent = this.arrayImage.findIndex((e) => e.name == this.file_name)
      if(indexParent != -1){
        var indexRectangleParent = this.arrayImage[indexParent].rectangle.findIndex((el) => el.id == data.id)
        this.arrayImage[indexParent].rectangle[indexRectangleParent] = data
      }else if(index != -1){
        var indexRectangle = this.temporaryImage[index].rectangle.findIndex((el) => el.id == data.id)
        this.temporaryImage[index].rectangle[indexRectangle] = data
      }
    },
    simpanImage(){
      // var thisParent = this;
      this.isloadingGambar = true;
      this.$refs.simpanImage.setAttribute('disabled', true);
      setTimeout(() => {
        var index = this.temporaryImage.findIndex((e) => e.name == this.file_name)
        var data = this.$refs.editor.invoke("toDataURL");
        this.file_image = data;
        if(index != -1){
          var indexx = this.arrayImage.findIndex((e) => e.name == this.file_name)
          this.getSizeImage(this.file_image).then(img => {
            if(indexx != -1){
              this.arrayImage[indexx].width = img.naturalWidth
              this.arrayImage[indexx].height = img.naturalHeight
            }else{
              this.temporaryImage[index].width = img.naturalWidth
              this.temporaryImage[index].height = img.naturalHeight
            }
          });
          
          if(indexx == -1){
            this.temporaryImage[index].dataImage = this.$refs.editor.invoke("toDataURL")
            this.arrayImage.push(this.temporaryImage[index])
          }else{
            this.arrayImage[indexx].dataImage = this.$refs.editor.invoke("toDataURL")
          }
        }
      this.$refs.simpanImage.removeAttribute('disabled');
      this.isloadingGambar = false
      }, 1500);
    },
    async getSizeImage(url) {
      const img = new Image();
      img.src = url;
      await img.decode();  
      return img
    },
    buttonTab(name){
      var index = this.arrayImage.findIndex((e) => e.name == name)
      if(index != -1){
        this.arrayImage[index].isActive = true
        this.arrayImage.forEach(element => {
          if(element.name != name){
            element.isActive = false;
          }
        });
        this.file_name =  this.arrayImage[index].name
        this.$refs.editor.invoke(
            "loadImageFromURL",
            this.arrayImage[index].url,
            this.arrayImage[index].name
          ).then(result=>{
              this.$refs.editor.invoke('ui.activeMenuEvent')
              if(this.arrayImage[index].rectangle.length > 0){
                this.arrayImage[index].rectangle.forEach(element => {
                    this.createRectangle(element)
                });
              }
              // this.$refs.editor.invoke('resizeCanvasDimension',{
              //     width:this.arrayImage[index].width < this.options.cssMaxWidth ? this.arrayImage[index].width  : this.options.cssMaxWidth,
              //     height:this.arrayImage[index].height < this.options.cssMaxHeight ? this.arrayImage[index].height  : this.options.cssMaxHeight
              // })
          })
      }
    },
    createRectangle(element) {
      setTimeout(() => {
        this.$refs.editor.invoke('addShape','rect', {
              fill: 'blue',
              stroke: element.stroke,
              strokeWidth: element.strokeWidth,
              left: element.left,
              top: element.top,
              width : element.width,
              height : element.height,
              isRegular: false,
              opacity: 0.5
          }).then(objectProps => {
              element.id = objectProps.id
          });
      }, 1000);

      setTimeout(() => {
        let textOptions = {
            content :element.posisi,
            styles: {
                fill: 'white',
                fontSize: 100,
                fontWeight: 'bold'
            },
            position: {
              x : element.left,
              y : element.top
            }
          }
      }, 1500);
    },
    switchMultiple(value){
      this.keyEditor += 1
      this.temporaryImage = []
      this.arrayImage = []
      this.fileCreateObjectURL = null
      this.file = null
      this.url = null
      this.$refs.oFileHandler.value = null;
    },
    async magicTool(){
        this.$refs.magictool.setAttribute('disabled', true);
        let data = this.$refs.editor.invoke("toDataURL");
        let canvas = this.$refs.editor.invoke("getCanvasSize");
        let file = this.dataURLtoFile(data,"file.jpeg");
        let formData = new FormData();
        formData.append("file",file);
        formData.append("width",canvas.width);
        formData.append("height",canvas.height);
        let response = await magictool(formData);
        let dataResult = response;
        let arrayRectangle = []
        let arrayText = []
        if(dataResult.message == "Berhasil"){
          if(dataResult.result.data.length > 0){
            const element = dataResult.result.data
            for (let index = 0; index < element.length; index++) {
              let rect = {
                    width: element[index].config.w,
                    height: element[index].config.h,
                    // fill:  element[index].color,
                    fill:  'Blue',
                    stroke: 'blue',
                    strokeWidth: 3,
                    left: element[index].config.x,
                    top:element[index].config.y,
                    opacity: 0.5,
              }
              let posisi = ""
              let text = ""
              if(this.switchEnable && this.arrayImage.length > 0){
                let indexImageLast = this.arrayImage.length - 1;
                let lengthPosisiLast = this.arrayImage[indexImageLast].rectangle.length;
                posisi = lengthPosisiLast + element[index].posisi
                text = posisi.toString();
              }else{
                posisi = element[index].posisi
                text = posisi.toString();
              }
              rect.posisi = posisi
              let textOptions = {
                  content :text,
                  styles: {
                      fill: 'white',
                      fontSize: 100,
                      fontWeight: 'bold'
                  },
                  position: {
                    x : element[index].config.x,
                    y : element[index].config.y
                  }
              }
              setTimeout(() => {
                this.$refs.editor.invoke('addShape','rect',rect ).then(objectProps => {
                  rect.id = objectProps.id
                  arrayRectangle.push(rect)
                });
                this.$refs.editor.invoke('discardSelection');
              }, 1500);

              // Numbering
              setTimeout(() => {
                this.$refs.editor.invoke('addText',text,textOptions ).then(objectProps => {
                    textOptions.id = objectProps.id
                    textOptions.content = text
                    arrayText.push(textOptions)
                });
                this.$refs.editor.invoke('discardSelection');
              }, 2500);
            }
            let index = this.temporaryImage.findIndex(obj => obj.name === this.$refs.editor.invoke('getImageName'));
            this.temporaryImage[index].rectangle = arrayRectangle
            this.temporaryImage[index].text = arrayText
            this.$refs.magictool.removeAttribute('disabled');
          }
      }else{
        this.$refs.magictool.removeAttribute('disabled');
      }
    },
    deleteNomor(indexObject){
      let index = this.temporaryImage.findIndex((e) => e.name === this.file.name);
      let indexRectangle = this.temporaryImage[index].rectangle.findIndex((e) => e.posisi == this.posisiAwal);
      let isLastIndex = indexObject === this.temporaryImage[index].rectangle.length - 1;
      this.deleteRectangle = []
      this.activeRectangle = null;

      setTimeout(() => {
        this.$refs.editor.invoke("removeObject",this.temporaryImage[index].rectangle[indexRectangle].id);
        this.temporaryImage[index].rectangle.splice(indexRectangle, 1);
      }, 1000);

      setTimeout(() => {
        this.$refs.editor.invoke("removeObject",this.temporaryImage[index].text[indexRectangle].id);
        this.temporaryImage[index].text.splice(indexRectangle, 1);
        this.$refs.editor.invoke('deactivateAll');

        for (let i = 0; i < this.temporaryImage[index].rectangle.length; i++) {
          if(i >= indexRectangle){
            let element = this.temporaryImage[index].rectangle[i];
            this.temporaryImage[index].rectangle[i].posisi = this.temporaryImage[index].rectangle[i].posisi - 1
            this.temporaryImage[index].text[i].content = this.temporaryImage[index].text[i].content - 1
          }
        }
      }, 1500);


      
      setTimeout(() => {
        for (let i = 0; i < this.temporaryImage[index].text.length; i++) {
          setTimeout(() => {
            let text = this.temporaryImage[index].text[i].content.toString();
            this.$refs.editor.invoke("setObjectProperties",this.temporaryImage[index].text[i].id,{
              text:text
            });
          }, 1000);
        }
      }, 2000);
      this.deleteRectangle = []
      this.activeRectangle = null;
      this.$refs.editor.invoke('discardSelection');
      this.closeModal()
    },
    changeNomor(){
      let tempImg = this.temporaryImage;
      let file = this.file;
      let posisiAwal = this.posisiAwal;
      let posisiAkhir = this.posisiAkhir;

      let index = tempImg.findIndex((e) => e.name === file.name);
      let indexAwal = tempImg[index].rectangle.findIndex((e) => e.posisi == posisiAwal);
      let indexAkhir = tempImg[index].rectangle.findIndex((e) => e.posisi == posisiAkhir);
      if (indexAkhir !== -1) {
        let delayTime = 1000;
        setTimeout(() => {
          let text = tempImg[index].text[indexAkhir].content.toString();
          this.$refs.editor.invoke("setObjectProperties", tempImg[index].text[indexAwal].id, {
            text: posisiAkhir.toString(),
          });
          this.$refs.editor.invoke("deactivateAll");
          tempImg[index].rectangle[indexAwal].posisi = posisiAkhir;
          tempImg[index].rectangle[indexAkhir].posisi = posisiAwal;
        }, delayTime);

        setTimeout(() => {
          let text = tempImg[index].text[indexAwal].content.toString();
          this.$refs.editor.invoke("setObjectProperties", tempImg[index].text[indexAkhir].id, {
            text: posisiAwal.toString(),
          });
          this.$refs.editor.invoke("deactivateAll");
          this.modalShow = false;
          tempImg[index].rectangle[indexAkhir].posisi = posisiAwal;
          tempImg[index].rectangle[indexAwal].posisi = posisiAkhir;

          this.posisiAkhir = "";
        }, delayTime + 500);

        this.modalShow = false;
        this.deleteRectangle = [];
        this.activeRectangle = null;
      } else {
        Swal.fire({
          text: "Maaf nomor yang anda masukan melebihi batas yang sudah ditentukan.",
          icon: "error",
          confirmButtonText: "Ok",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-light-primary",
          },
        });
        this.modalShow = false;
        this.deleteRectangle = [];
        this.activeRectangle = null;
      }

    },
    closeModal(){
      this.modalShow = false
      this.$refs.editor.invoke('discardSelection');
      this.$refs.editor.invoke('deactivateAll')
    }
},
watch:{
    dataContent(){
      this.valueContent = [];
      for (let index = 0; index < this.dataContent.length; index++) {
        this.valueContent.push(this.dataContent[index]["value"]);
        
      }
      this.content = "";
      this.content = this.valueContent.toString().replaceAll(",", " ");
    },
    dataTitle(){
      this.valueTitle = [];
      for (let index = 0; index < this.dataTitle.length; index++) {
        this.valueTitle.push(this.dataTitle[index]["value"]);
      }
      this.title = "";
      this.title = this.valueTitle.toString().replaceAll(",", " ");
    },
  }
};
</script>
<style scoped>
.imageEditorApp {
  height: 100vh;
}
</style>
